var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"scheme-selectors_wrapper type-acceptance"},[_c('VGridCol',{staticClass:"create-violation__fieldset"},[(!_vm.isCreateViolation)?_c('VGridRow',{staticClass:"grid-row_justify-center create-violation__margin_bottom"},[_c('VGridCol',{staticClass:"text_header grid-col_justify-center",attrs:{"sm-4":"","xs-12":""}},[_vm._v(" "+_vm._s(_vm.$t('page.statusSelector.title'))+" ")]),_c('VGridCol',{attrs:{"sm-8":"","xs-12":""}},[_c('VInput',{staticClass:"create-violation__input acceptance-disabled",class:{
                        'create-violation__input--in-work': _vm.violation.statusId === _vm.StatusEnum.InWork,
                        'create-violation__input--done': _vm.violation.statusId === _vm.StatusEnum.Done,
                        'create-violation__input--accept': _vm.violation.statusId === _vm.StatusEnum.Accept,
                        'create-violation__input--cancelled': _vm.violation.statusId === _vm.StatusEnum.Rejected,
                        'create-violation__input--created': _vm.violation.statusId === _vm.StatusEnum.Created,
                    },attrs:{"placeholder":_vm.$t('page.statusSelector.placeholder'),"disabled":"","type":"text"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1):_vm._e(),_c('VGridRow',{staticClass:"grid-row_justify-center create-violation__margin_bottom"},[_c('VGridCol',{staticClass:"text_header grid-col_justify-center",attrs:{"sm-4":"","xs-12":""}},[_vm._v(" "+_vm._s(_vm.$t('page.typeSelector.title'))+" ")]),_c('VGridCol',{attrs:{"sm-8":"","xs-12":""}},[_c('VInput',{staticClass:"create-violation__input",attrs:{"placeholder":_vm.$t('page.typeSelector.placeholder'),"disabled":"","type":"text"},model:{value:(_vm.remarkType),callback:function ($$v) {_vm.remarkType=$$v},expression:"remarkType"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }